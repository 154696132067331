
import { defineComponent, onMounted, reactive, ref } from 'vue';
import _has from 'lodash/has';
import { useRoute, useRouter } from 'vue-router';

import Store from '@/store';
import Toast from '@/toast';

import ProjectService from '@/services/project.service';

import User from '@/models/user.model';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import Input from '@/components/form/Input.vue';
import UserInput from '@/components/form/UserInput.vue';
import Editor from '@/components/form/Editor.vue';
import Project from '@/models/project.model';

interface Form {
	title: string;
	projectManager: User[] | number[];
	description: string;
}

export default defineComponent({
	components: {
		Layout,
		Loader,
		Input,
		UserInput,
		Editor,
	},

	setup() {
		const route = useRoute();
		const router = useRouter();

		const loading = ref(true);
		const isArchived = ref(false);

		const pageLoader = ref(false);
		const updateLoader = ref(false);

		const projectId = Number(route.params.id);
		const project = Store.get('project');

		const form: Form = reactive({
			title: '',
			projectManager: [],
			description: '',
		});

		// load Project on mounted
		onMounted(async () => {
			if (!project.value) {
				await ProjectService.show(projectId)
					.then((response) => {
						if (response) {
							Store.set('project', response);

							form.title = response.title;
							form.description = response.description || '';
							form.projectManager = response.owner_id ? [response.owner_id] : [];
							isArchived.value = !!response.archived;
						}
					})
					.finally(() => (loading.value = false));

				return;
			}

			form.title = project.value.title;
			form.description = project.value.description;
			form.projectManager = project.value.owner_id ? [project.value.owner_id] : [];
			isArchived.value = !!project.value.archived;

			loading.value = false;
		});

		async function save() {
			updateLoader.value = true;
			let projectManagerId = form.projectManager.length && form.projectManager[0];

			if (typeof projectManagerId !== 'number' && _has(projectManagerId, 'id')) {
				projectManagerId = Number(projectManagerId.id);
			}

			await ProjectService.update(projectId, {
				title: form.title,
				archived: false,
				description: form.description,
				owner_id: Number(projectManagerId) === 0 ? null : Number(projectManagerId),
			})
				.then((response) => {
					if (response) {
						const data = response;

						const index = Store.get('projects').value.findIndex(
							(project: Project) => project.id == data.id
						);

						Store.get('projects').value.splice(index, 1, data);

						Store.set('project', {
							...Store.get('project'),
							title: data.title,
							description: data.description,
							owner_id: data.owner_id,
						});
					}
				})
				.finally(() => {
					updateLoader.value = false;
				});
		}

		async function archiveProject() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			pageLoader.value = true;

			let currentProject = project.value;

			if (!currentProject) {
				await ProjectService.show(projectId).then((response) => {
					if (response) {
						Store.set('project', response);

						currentProject = response;
					}
				});
			}

			const archive = !isArchived.value;

			await ProjectService.update(projectId, {
				title: currentProject.title,
				archived: archive,
			})
				.then((response) => {
					if (response) {
						isArchived.value = archive;
						currentProject.archived = archive;

						Store.set('project', currentProject);

						Store.set(
							'projects',
							Store.get('projects').value.map((p: Project) => {
								if (p.id === projectId) {
									p.archived = archive;
								}

								return p;
							})
						);

						Toast.success(
							'Arkivera verkætlan',
							archive ? 'Verkætlanin er nú arkiverað' : 'Verkætlanin er tikið úr arkivinum'
						);
					}
				})
				.catch(() => {
					Toast.success(
						'Arkivera verkætlan',
						archive
							? 'Tað eydnaðist ikki at arkivera verkætlan'
							: 'Tað eydnaðist ikki at taka verkætlan úr arkivinum'
					);
				})
				.finally(() => {
					pageLoader.value = false;
				});
		}

		async function deleteProject() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			pageLoader.value = true;

			await ProjectService.delete(projectId)
				.then((response) => {
					if (response) {
						Store.set(
							'projects',
							Store.get('projects').value.filter((p: Project) => p.id != projectId)
						);

						router.push({ name: 'Projects' });
					}
				})
				.finally(() => {
					pageLoader.value = false;
				});
		}

		return {
			// data
			form,
			loading,
			project,
			updateLoader,
			pageLoader,
			isArchived,

			// functions
			save,
			archiveProject,
			deleteProject,
		};
	},
});
